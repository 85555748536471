
import Vue from 'vue'
import { images, metadata } from '~/assets/ts/utils/metadata'
import HymnalTabbedHymnal from '~/components/hymnal/Tabbed.vue'

export default Vue.extend({
  components: { HymnalTabbedHymnal },
  layout: 'site',
  head() {
    return metadata(this, {
      title: `${this.$t('Hymnal')} | SermonAudio`,
      description: this.$t(
        'A free collection of over {hymnCount} hymns, including historical information and audio extras.',
        { hymnCount: 800 }
      ),
      images: images(
        `https://media.sermonaudio.com/images/web/images/og-hymn.jpg`,
        true
      ),
    })
  },
  computed: {
    tabQuery(): string {
      const tab = this.$route.query.tab
      const valid =
        tab &&
        ['popular', 'alphabetical', 'psalter', 'tunes'].includes(tab.toString())
      return valid ? tab.toString() : 'popular'
    },
  },
})
